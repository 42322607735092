import Head from "next/head";
import Image from "next/image";
import { metadata } from "../config";
import styles from "./Index.module.scss";
import { motion } from "framer-motion";
import { useState } from "react";
import { FabulousAttentionWhoreButton } from "../components/FabulousAttentionWhoreButton";
import { useRouter } from "next/router";
import { subscribe } from "../api-client/subscribe";
import { ScrollDownArrow } from "../components/icons/ScrollDownArrow";
import { Citation } from "../components/Citation/Citation";
import { Button } from "../components/Button/Button";
import { TwitterLogo } from "../components/icons/TwitterLogo";
import { GitHubLogo } from "../components/icons/GitHubLogo";
import { LinkedInLogo } from "../components/icons/LinkedInLogo";
import { Footer } from "../components/Footer/Footer";

export default function Home() {
  const [email, setEmail] = useState("");
  const router = useRouter();
  const navigate = (path: string) => router.push(path);
  const r = router.query.r as string | undefined;

  const scrollToTop = () => {
    if (!window) return;

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className={styles.container}>
      <Head>
        <title>{metadata.title}</title>
        <meta name="description" content={metadata.description} />
        <link rel="icon" href="/favicon.png" />
      </Head>

      <div className={styles.main}>
        <div className={styles.heroContainer}>
          <div>
            <Image src="/logo-white.png" alt="Logo" width={300} height={120} />
          </div>

          <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{
              duration: 0.3,
              type: "spring",
              stiffness: 400,
              damping: 40,
              mass: 1,
            }}
          >
            <h1 className={styles.title}>
              Join the community
              <br />
              of developers who care
            </h1>
          </motion.div>

          <div className={styles.column}>
            <motion.div
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{
                duration: 0.3,
                delay: 0.2,
                type: "spring",
                stiffness: 400,
                damping: 20,
                mass: 1,
              }}
              className={styles.blend}
            >
              <p className={styles.description}>
                Get exclusive deals on cloud, ebooks, software and courses for
                developers. Support research-backed charities at the same time.
              </p>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{
                duration: 0.3,
                delay: 0.25,
                type: "spring",
                stiffness: 400,
                damping: 20,
                mass: 1,
              }}
            >
              <form>
                <input
                  type="email"
                  placeholder="Enter your email"
                  className={styles.input}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <FabulousAttentionWhoreButton
                  style={{ width: "100%" }}
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    subscribe(email, navigate, { r });
                  }}
                >
                  Sign up
                </FabulousAttentionWhoreButton>
              </form>
              <p className={styles.info}>Get notified when we launch.</p>
            </motion.div>
          </div>
          <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{
              duration: 0.6,
              delay: 0.85,
              type: "spring",
              stiffness: 400,
              damping: 20,
              mass: 1,
            }}
            className={styles.toBottom}
          >
            <span>Find out more</span>
            <ScrollDownArrow />
          </motion.div>
        </div>
        {/* <div className={styles.partners}>
          <DigitalOcean width="150" />
          <IntelliJ width="152" />
          <NordVPN width="125" />
          <Atlassian width="150" />
        </div> */}
      </div>
      <div className={styles.copyContainer}>
        <div className={styles.copy}>
          <div className={styles.firstCopy}>
            <h1>The why</h1>
            <p>
              There are people and animals who suffer even though they don't
              have to. 1 in 10 people don't have clean water
              <Citation link="https://washdata.org/sites/default/files/2021-07/jmp-2021-wash-households.pdf">
                [1]
              </Citation>
              . Also 1 in 10 people suffer from hunger (and the war in Ukraine
              is creating the biggest global food crisis since the Second World
              War)
              <Citation link="https://www.who.int/news/item/06-07-2022-un-report--global-hunger-numbers-rose-to-as-many-as-828-million-in-2021">
                [2]
              </Citation>
              . 1 in 7 people are living with vision loss because they lack
              access to medical care
              <Citation link="https://helenkellerintl.org/what-we-do/saving-sight/">
                [3]
              </Citation>
              . Around 600,000 people die from malaria each year and 70% of them
              are children under 5
              <Citation link="https://www.who.int/news-room/fact-sheets/detail/malaria">
                [4]
              </Citation>
              . Malaria is preventable. Another few hundred thousand die from
              schistosomiasis - also preventable
              <Citation link="https://web.archive.org/web/20140312203924/http://www.who.int/mediacentre/factsheets/fs115/en/">
                [5]
              </Citation>
              .
            </p>
            <p>
              At the same time, globally there is more wealth than ever before.
              Big portion of it has been created leveraging the internet. And
              the internet is still growing. Global eCommerce reached $5
              trillion dollars in 2021
              <Citation link="https://www.statista.com/statistics/379046/worldwide-retail-e-commerce-sales/">
                [6]
              </Citation>
              . If we could direct only a tiny portion of it to help feeling
              beings who suffer, we could make a huge difference.
            </p>
            <p>
              We believe it can and should be done through a market solution. So
              that it is sustainable and scalable. Optimized for positive social
              impact rather than profits of the shareholders.
            </p>
          </div>
          <div className={styles.copyBlock}>
            <h1>The idea</h1>
            <p>
              The idea is to gather a community of people, like you, who care
              about others. Then offer deals on products you enjoy from
              companies who want to promote what they sell while supporting good
              causes.{" "}
              <strong>
                You get discounts, companies get more customers, and charities
                get more money.
              </strong>
            </p>
          </div>
          <div className={styles.copyBlock}>
            <h1>The plan</h1>
            <p>We have a simple plan:</p>
            <ol>
              <li>
                Bring together a community of developers who want to help
                others.
              </li>
              <li>
                Generate donations for charities from deals on products that
                developers love
              </li>
              <li>
                With the help of the community, scale the project to other
                audiences and industries.
              </li>
              <li>
                Make donating a portion of the income from products a standard,
                creating an economy in which every transaction helps someone.
              </li>
            </ol>
            <p>
              In short, build for developers first, and then, with their help,
              build for everyone else.
            </p>
          </div>

          <div className={styles.ctaBlock}>
            <Button
              onClick={() => {
                scrollToTop();
              }}
            >
              Click here to join
            </Button>
          </div>

          <div className={styles.copyBlock}>
            <h1>How to do it right</h1>
            <p>
              If we want to build something that will last, something truly
              impactful, we need to build it on solid foundations. Here are some
              of the things we are doing to make sure that we, in fact, do it
              right:
            </p>
            <h2>1. Social enterprise</h2>
            <p>
              We are registered as a not-for-profit social enterprise - a
              Community Interest Company. It is a type of company introduced by
              the UK government specifically designed for companies that want to
              use their profits and assets for the public good.
            </p>
            <h2>2. Research-backed charities</h2>
            <p>
              Research shows that some non-profits can be 100x as effective as
              the average organization.
              <Citation link="https://www.givingwhatwecan.org/charity-comparisons">
                [7]
              </Citation>{" "}
              We are carefully selecting charities that have been thoroughly
              researched and vetted by independent evaluators like GiveWell,
              Animal Charity Evaluators, The Life You Can Save.
            </p>
            <p>
              We believe it is crucial to follow both your heart and your head
              when it comes to helping others. Even though the research into the
              effectiveness of charities is in its early stages, we think it's
              still the wisest choice to follow the highest quality of the
              information we currently have access to.
            </p>
            <h2>3. Transparency</h2>
            <p>
              Transparency builds trust and keeps us accountable. That's why we
              will publish all the donation receipts and the financial
              statements of the company. Same as we did when we launched
              appsforukraine.com (
              <a href="https://altruisto.com/ukraine/receipts" target="_blank">
                see here
              </a>
              ).
            </p>
            <h2>4. Respecting privacy</h2>
            <p>
              The privacy of your data is a big deal for us. We will never sell
              your data. Ever.
            </p>
            <p>
              For example, we switched from Google Analytics to Plausible. It's
              a privacy-first alternative that is compliant with GDPR, CCPA,
              PECR (and donates 5% of their revenue).
            </p>
            <p>
              In places where we need to have access to information to make our
              service better, we will clearly state it. For example - we are
              measuring the number of opens of our emails via our email provider
              - MailerLite.
            </p>
            <h2>5. Open source</h2>
            <p>
              Open source is awesome. It makes the internet better. It is what
              let the internet become what it is today. And therefore we want to
              open source as much of our work as possible. Check out the github
              of this page:{" "}
              <a
                href="https://github.com/altruisto/devsforgood"
                target="_blank"
                rel="noreferrer"
              >
                https://github.com/altruisto/devsforgood
              </a>
            </p>
          </div>
          <div className={styles.ctaBlock}>
            <Button
              onClick={() => {
                scrollToTop();
              }}
            >
              Click here to join
            </Button>
          </div>
          <div className={styles.copyBlock}>
            <h1>Why now</h1>
            <h2>The shift towards social impact</h2>
            <p>
              The spirit of the times is changing. People care much more about
              the impact of the products they buy and the companies they
              support. We are more likely to buy a product that supports a
              charitable cause
              <Citation link="https://link.springer.com/article/10.1007/s10551-020-04639-6">
                [8]
              </Citation>
              . A lot of companies are already noticing the change and trying to
              capitalize on it by using social impact as a superficial marketing
              tool, without actually making meaningful changes. We believe
              people will, in the long run, value an authentic approach and the
              social impact will become a major factor - at least for some
              consumers. Let's offer ourselves, as we're all consumers, a chance
              to be part of this change.
            </p>
            <h2>The Artificial Intelligence Revolution</h2>
            <p>
              The next revolution is here. AI will be more profound than the
              industrial or the internet revolution. It will change everything.
              New possibilities will open up, new industries will emerge. And an
              immense amount of wealth will be created in the process. It's
              crucial to make sure that, at least some of it, will be used to
              help people and other animals who suffer.
            </p>
            <p>
              It's hard to imagine that AI is not going to be linked tightly to
              the internet. So as the internet builders we are in a prime
              position to make sure that it is used for good.
            </p>
            <p>
              We don't have much time before the world becomes unrecognizable.
              We need to start preparing now.
            </p>
          </div>
          <div className={styles.ctaBlock}>
            <Button
              onClick={() => {
                scrollToTop();
              }}
            >
              Click here to join
            </Button>
          </div>
          <div className={styles.copyBlock}>
            <h1>Potential criticism</h1>
            <p>
              We found a study showing that people who purchased a product whose
              price included a charitable donation were making smaller donations
              themselves
              <Citation link="https://www.semanticscholar.org/paper/Can-Supporting-a-Cause-Decrease-Donations-and-The-Krishna/96c6d645077c29c749680a0604dd4f3d397ad0c7?p2df">
                [9]
              </Citation>
              . It might be caused by people thinking that they have already
              done their part and don't have to do more (known as "moral
              licensing"). We've reached out to the author and learned that the
              study, as far as she knows, was not replicated. We are going to
              observe this field of research. As for now, given that many
              studies in psychology do not replicate
              <Citation link="https://authors.library.caltech.edu/91063/">
                [10]
              </Citation>
              , we don't think it's enough to conclude that the effect of
              supporting causes via purchases is negative.
            </p>
          </div>
          <div className={styles.copyBlock}>
            <h1>Who is behind it</h1>

            <p>
              I'm a senior software engineer by day and a social entrepreneur by
              night. I've been writing code since I was 12, when I got a book
              about PHP as a Christmas Gift from my parents. While studying
              psychology, my second biggest passion behind tech, I've discovered
              the works of Peter Singer, Toby Ord, Will MacAskill - philosophers
              who argue that we should not only try to do good, but also do it
              as effectively as possible. And since then, I've been trying to
              use my skills and talents to help others.
            </p>
            <div className={styles.author}>
              <img
                src="/authors/daniel-w.jpeg"
                alt="Photo of Daniel Wyrzykowski"
                className={styles.authorPhoto}
              />
              <div>
                <p>
                  Daniel Wyrzykowski - Founder of Altruisto, Apps for Ukraine,
                  and Devs for Good.
                </p>
                <div className={styles.authorSocialMedia}>
                  <a
                    href="https://twitter.com/danielmakestech"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <TwitterLogo />
                  </a>
                  <a
                    href="https://www.linkedin.com/in/danielwyrzykowski/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <LinkedInLogo />
                  </a>
                  <a
                    href="https://github.com/kovsky0"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <GitHubLogo style={{ width: 21 }} />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.ctaBlock}>
            <Button
              onClick={() => {
                scrollToTop();
              }}
            >
              Click here to join
            </Button>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
