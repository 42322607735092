import { FunctionComponent, useEffect, useState } from "react";
import { useInterval } from "usehooks-ts";
import styles from "./FabulousAttentionWhoreButton.module.css";

export const FabulousAttentionWhoreButton: FunctionComponent<
  JSX.IntrinsicElements["button"]
> = (props) => {
  const [classNames, setClassNames] = useState<string[]>([styles.fabulous]);
  const [isHovered, setIsHovered] = useState(false);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setClassNames((classNames) => {
  //       if (classNames.length === 1 && !isHovered) {
  //         return [styles.fabulous, styles.shaker];
  //       } else {
  //         return [styles.fabulous];
  //       }
  //     });
  //   }, 2500);

  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, []);

  useInterval(
    () => {
      setClassNames((classNames) => {
        if (classNames.length === 1) {
          return [styles.fabulous, styles.shaker];
        } else {
          return [styles.fabulous];
        }
      });
    },
    isHovered ? null : 2500
  );

  return (
    <button
      className={classNames.join(" ")}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      {...props}
    >
      <span>{props.children}</span>
    </button>
  );
};
