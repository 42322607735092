import { toast } from "react-toastify";
import * as Sentry from "@sentry/nextjs";

type NavigateFn = (path: string) => Promise<boolean>;

type Payload = {
  group?: string;
  r?: string;
};

export const subscribe = async (
  email: string,
  navigate: NavigateFn,
  { group, r }: Payload
) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({
    email,
    groups: group ? [group] : undefined,
    fields: r ? { r } : undefined,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
  };
  try {
    const res = await fetch(`/api/subscribe`, requestOptions);
    if (res.status === 201) {
      navigate("/confirm?email=" + email);
      return;
    }

    Sentry.captureException(JSON.stringify(res));

    toast("Something went wrong. Please try again later.", {
      type: "error",
    });
  } catch (err) {
    Sentry.captureException(err);
    toast("Something went wrong. Please try again later.", {
      type: "error",
    });
  }
};
