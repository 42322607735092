export const ScrollDownArrow = () => (
  <svg
    width="13"
    height="50"
    viewBox="0 0 9 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 1.43478V0.934783L5 0.934783V1.43478L4 1.43478ZM4.85355 41.1596C4.65829 41.3548 4.34171 41.3548 4.14645 41.1596L0.964466 37.9776C0.769204 37.7823 0.769204 37.4657 0.964466 37.2705C1.15973 37.0752 1.47631 37.0752 1.67157 37.2705L4.5 40.0989L7.32843 37.2705C7.52369 37.0752 7.84027 37.0752 8.03553 37.2705C8.2308 37.4657 8.2308 37.7823 8.03553 37.9776L4.85355 41.1596ZM5 1.43478L5 40.806H4L4 1.43478L5 1.43478Z"
      fill="currentColor"
    ></path>
  </svg>
);
