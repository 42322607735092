import styles from "./Footer.module.scss";

export const Footer = () => {
  return (
    <footer className={styles.root}>
      Made with &#10084; in Warsaw, Berlin, Rotherham.
      <div className={styles.info}>
        <small>
          Well-Managed World C.I.C.
          <br />
          Unit 4e Enterprise Court
          <br />
          Farfield Park, Rotherham
          <br />
          South Yorkshire
          <br />
          S63 5DB, England
          <br />
          <br />
          <a href="/privacy-policy">Privacy Policy</a>&nbsp; | &nbsp;
          <a href="/terms-of-service">Terms of Service</a>
        </small>
      </div>
    </footer>
  );
};
